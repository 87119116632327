import { useQuery } from '@tanstack/react-query';
import { minutesToMilliseconds } from 'date-fns';
import { webQueries } from '@qb/httpRequest/webQueries';

export const usePartsAutocompleteRequest = (query: string) => {
  const { isLoading: arePartsLoading, data: parts = [] } = useQuery({
    ...webQueries.search.partAutocompleteOmni(query),
    keepPreviousData: true,
    staleTime: minutesToMilliseconds(1),
    select: (data) => data.parts,
    enabled: Boolean(query),
  });

  return { arePartsLoading, parts };
};
