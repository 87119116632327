'use client';

import React, { ChangeEventHandler, FocusEventHandler, KeyboardEventHandler, useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { hoursToMilliseconds } from 'date-fns';
import { useRouter } from 'next/navigation';
import SearchIcon from '@mui/icons-material/Search';
import { InputAdornment, OutlinedInput, OutlinedInputProps, SxProps } from '@mui/material';
import { recordEvent } from '@qb/frontend/analytics/ga4';
import { mergeSx } from '@qb/frontend/utils/mergeSx';
import { webQueries } from '@qb/httpRequest/webQueries';
import { IconMagic } from '@/src/components/Icons/IconMagic';
export type OmniSearchInputG4AEventsProps = {
  searchInputName: '' | 'NavbarSearch' | 'HomePageHeroSearch' | 'HomePageSearchBanner';
};
type Props = OutlinedInputProps & OmniSearchInputG4AEventsProps & {
  magicIconSx?: SxProps;
};
export const OmniSearchInput = ({
  onFocus,
  onKeyDown,
  onChange,
  searchInputName,
  sx,
  magicIconSx,
  ...rest
}: Props) => {
  const queryClient = useQueryClient();
  const router = useRouter();
  const onKeyDownHandler = useCallback<KeyboardEventHandler<HTMLTextAreaElement | HTMLInputElement>>(event => {
    const query = event.currentTarget.value;
    if (event.key === 'Enter' && query) {
      event.currentTarget.blur();
      router.push(`/search/part?query=${query}`);
    }
    if (onKeyDown) {
      onKeyDown(event);
    }
  }, [onKeyDown, router]);
  const onFocusHandler = useCallback<FocusEventHandler<HTMLInputElement>>(event => {
    if (onFocus) {
      onFocus(event);
    }
    if (searchInputName) {
      const query = event.currentTarget.value;
      recordEvent('search_focus', {
        attributes: {
          search_phrase: query,
          search_type: 'freeform',
          search_inputbox_name: searchInputName
        }
      });
    }
  }, [onFocus, searchInputName]);
  const onChangeHandler = useCallback<ChangeEventHandler<HTMLInputElement>>(event => {
    if (onChange) {
      onChange(event);
    }
  }, [onChange]);
  const onMouseEnterHandler = useCallback(() => {
    queryClient.prefetchQuery({
      ...webQueries.partCategory.searchCategories,
      staleTime: hoursToMilliseconds(1)
    });
  }, [queryClient]);
  return <OutlinedInput placeholder="Search by part, # or keyword" size="small" startAdornment={<InputAdornment sx={{
    pl: 0.5,
    mr: 0
  }} position="start">
          <SearchIcon color="primary" />
        </InputAdornment>} endAdornment={<IconMagic sx={magicIconSx} />} inputProps={{
    sx: {
      height: 24,
      pl: 1,
      py: 0.75,
      '&::placeholder': {
        color: 'text.secondary'
      }
    },
    type: 'search',
    enterKeyHint: 'search'
  }} sx={mergeSx({
    zIndex: theme => theme.zIndex.appBar + 1,
    width: '100%',
    bgcolor: 'common.white',
    pl: 1,
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: 'primary.main',
      borderWidth: 2
    },
    '&.MuiOutlinedInput-root': {
      pr: 0
    }
  }, sx)} {...rest} onKeyDown={onKeyDownHandler} onFocus={onFocusHandler} onChange={onChangeHandler} onMouseEnter={onMouseEnterHandler} data-sentry-element="OutlinedInput" data-sentry-component="OmniSearchInput" data-sentry-source-file="OmniSearchInput.tsx" />;
};