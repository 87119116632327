import { memo } from 'react';
import { Box, Container, Typography, Grid, Stack } from '@mui/material';
import { PartCategoryFooterCategoriesResponse } from '@/shared/types/controllers/PartCategoryControllerTypes';
import { LogoWithTagLine } from '../../LogoWithTagLine/LogoWithTagLine';
import { BottomFooter } from './components/BottomFooter';
import { Disclaimer } from './components/Disclaimer';
import { FooterNav } from './components/FooterNav';
type Props = {
  footerCategories: PartCategoryFooterCategoriesResponse['partCategories'];
};
const FooterInternal = ({
  footerCategories
}: Props) => {
  return <Stack component="footer" sx={{
    width: '100%',
    backgroundColor: 'common.white',
    pt: {
      xs: 4,
      lg: 8
    }
  }} data-sentry-element="Stack" data-sentry-component="FooterInternal" data-sentry-source-file="Footer.tsx">
      <Container data-sentry-element="Container" data-sentry-source-file="Footer.tsx">
        <Grid container data-sentry-element="Grid" data-sentry-source-file="Footer.tsx">
          <Grid item xs={12} lg={4} xl={3} data-sentry-element="Grid" data-sentry-source-file="Footer.tsx">
            <LogoWithTagLine height={50} data-sentry-element="LogoWithTagLine" data-sentry-source-file="Footer.tsx" />
            <Typography sx={{
            fontSize: 18,
            lineHeight: 2,
            mt: 3,
            mb: 2
          }} data-sentry-element="Typography" data-sentry-source-file="Footer.tsx">
              Save time searching for competitively priced automation parts.
              Search, find, and&nbsp;get the parts you need shipped to&nbsp;keep
              your projects on track.
            </Typography>
          </Grid>
          <Grid item xs={12} lg={8} xl={9} data-sentry-element="Grid" data-sentry-source-file="Footer.tsx">
            <FooterNav footerCategories={footerCategories} data-sentry-element="FooterNav" data-sentry-source-file="Footer.tsx" />
          </Grid>
        </Grid>
      </Container>
      <Container data-sentry-element="Container" data-sentry-source-file="Footer.tsx">
        <Box sx={{
        py: 2,
        display: 'flex',
        justifyContent: 'space-between'
      }} data-sentry-element="Box" data-sentry-source-file="Footer.tsx">
          <Typography component="span" variant="bodySmall" data-sentry-element="Typography" data-sentry-source-file="Footer.tsx">
            {`Copyright @ ${new Date().getFullYear()} Quotebeam`}
          </Typography>
        </Box>
      </Container>
      <BottomFooter data-sentry-element="BottomFooter" data-sentry-source-file="Footer.tsx" />
      <Disclaimer data-sentry-element="Disclaimer" data-sentry-source-file="Footer.tsx" />
    </Stack>;
};
export const Footer = memo(FooterInternal);