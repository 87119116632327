'use client';

import { usePathname } from 'next/navigation';
import { Box, Typography } from '@mui/material';
export const Disclaimer = () => {
  const pathname = usePathname();
  const isPartDisplayPage = pathname.startsWith('/part/');
  return <Box sx={{
    pt: 2,
    pb: isPartDisplayPage ? {
      xs: 15,
      md: 9
    } : 9,
    px: 3,
    backgroundColor: 'background.paper'
  }} data-sentry-element="Box" data-sentry-component="Disclaimer" data-sentry-source-file="Disclaimer.tsx">
      <Typography variant="bodySmall" align="justify" mb={2} data-sentry-element="Typography" data-sentry-source-file="Disclaimer.tsx">
        <Typography variant="bodySmall" fontWeight={600} component="span" data-sentry-element="Typography" data-sentry-source-file="Disclaimer.tsx">
          Disclaimer:
        </Typography>{' '}
        Quotebeam is a platform that brings products from many supply chain
        channels to one place to help customers source their parts without
        endless searching on the web. Our products are never used surplus, and
        are mainly overstock from other suppliers in the industry. Quotebeam is
        not an authorized distributor, authorized reseller, affiliate or
        representative for the manufacturers listed and products featured on
        this website. All product names, designated trademarks, brand names,
        brands and logos used on this site are the property of their respective
        owners. The depiction, description or sale of products featuring these
        names, trademarks, brands and logos is for identification purposes only
        and is not intended to indicate any affiliation with or authorization by
        any rights holders.
      </Typography>
      <Typography variant="bodySmall" align="justify" data-sentry-element="Typography" data-sentry-source-file="Disclaimer.tsx">
        <Typography variant="bodySmall" fontWeight={600} component="span" data-sentry-element="Typography" data-sentry-source-file="Disclaimer.tsx">
          Product Disclaimer:
        </Typography>{' '}
        The product is unused surplus. Quotebeam is not an authorized surplus
        dealer or affiliate for the Manufacturer of this product. The product
        may have older date codes or be from an earlier series than what is
        available directly from the manufacturer or its authorized dealers.
        Because Quotebeam is not an authorized distributor of this product, the
        Original Manufacturer’s warranty does not apply to this product.
        Quotebeam offers limited warranty for this product, which is not to be
        mistaken with the manufacturer warranty. While many Controls products
        will have firmware already installed, Quotebeam makes no representation
        as to whether the Control product will or will not have firmware and, if
        it does have firmware, whether the firmware is the revision level that
        you need for your application. Quotebeam also makes no representations
        as to your ability or right to download or otherwise obtain firmware for
        the product from the manufacturer, its distributors, or any other
        source. Quotebeam also makes no representations as to your right to
        install any such firmware on the product. Quotebeam will not obtain or
        supply firmware on your behalf. It is your obligation to comply with the
        terms of any End-User License Agreement or similar document related to
        obtaining or installing firmware. Customer acknowledges and agrees that
        Quotebeam does not provide any operating system software or software
        right-to-use licenses with the products it sells.
      </Typography>
    </Box>;
};