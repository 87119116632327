'use client';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionSummary, AccordionDetails, ListItem, List, Link } from '@mui/material';
import { PartCategoryFooterCategoriesResponse } from '@/shared/types/controllers/PartCategoryControllerTypes';
import { generatePartCategorySearchURL } from '@/shared/utils/URLs';
import { navLinkSx } from '@/src/components/Layouts/AppLayout/Footer/components/FooterNav';
type FooterCategories = PartCategoryFooterCategoriesResponse['partCategories'];
const RootCategory = ({
  category
}: {
  category: FooterCategories[0];
}) => {
  if (category.childPartCategories && category.childPartCategories.length) {
    return <Accordion disableGutters key={category.id} sx={{
      p: 0,
      borderWidth: 0,
      '&:before': {
        display: 'none'
      }
    }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`${category.id}-content`} id={`${category.id}-header`} sx={{
        p: 0,
        borderWidth: 0,
        minHeight: 0,
        justifyContent: 'start',
        '.MuiAccordionSummary-content': {
          my: 0,
          flexGrow: 0,
          pr: 1
        }
      }}>
          <Link variant="bodyMedium" sx={navLinkSx} onClick={e => e.stopPropagation()} href={generatePartCategorySearchURL(category.name, category.id)}>
            {category.name}
          </Link>
        </AccordionSummary>
        <AccordionDetails sx={{
        p: 0,
        pb: 1,
        pl: 1.5,
        borderWidth: 0
      }}>
          <List sx={{
          p: 0
        }}>
            {category.childPartCategories.map(childCategory => <ListItem key={childCategory.id} sx={{
            p: 0
          }}>
                <Link variant="bodySmall" sx={{
              ...navLinkSx,
              py: 1,
              fontWeight: 400
            }} href={generatePartCategorySearchURL(childCategory.name, childCategory.id)}>
                  {childCategory.name}
                </Link>
              </ListItem>)}
          </List>
        </AccordionDetails>
      </Accordion>;
  }
  return <Link variant="bodyMedium" sx={navLinkSx} href={generatePartCategorySearchURL(category.name, category.id)} data-sentry-element="Link" data-sentry-component="RootCategory" data-sentry-source-file="FooterCategoriesLinks.tsx">
      {category.name}
    </Link>;
};
type Props = {
  footerCategories: FooterCategories;
};
export const FooterCategoriesLinks = ({
  footerCategories
}: Props) => {
  if (!Array.isArray(footerCategories)) {
    return null;
  }
  return <>
      {footerCategories.map(category => <RootCategory key={category.id} category={category} />)}
    </>;
};