import { Stack } from '@mui/material';
const hubspotSnippet = `
<!--HubSpot Call-to-Action Code -->
<span className="hs-cta-wrapper" id="hs-cta-wrapper-c1a3ae43-c119-4d64-9b7c-c8a20ba7868d">
   <span className="hs-cta-node hs-cta-c1a3ae43-c119-4d64-9b7c-c8a20ba7868d" id="hs-cta-c1a3ae43-c119-4d64-9b7c-c8a20ba7868d">
      <!--[if lte IE 8]>
      <div id="hs-cta-ie-element"></div>
      <![endif]-->
      <a href="https://cta-redirect.hubspot.com/cta/redirect/20244782/c1a3ae43-c119-4d64-9b7c-c8a20ba7868d">
      <img className="hs-cta-img" id="hs-cta-img-c1a3ae43-c119-4d64-9b7c-c8a20ba7868d" style="border-width:0px;"
         src="https://no-cache.hubspot.com/cta/default/20244782/c1a3ae43-c119-4d64-9b7c-c8a20ba7868d.png"
         alt="Welcome to Quotebeam" /></a>
   </span>
   <script charset="utf-8" src="https://js.hscta.net/cta/current.js"></script>
   <script type="text/javascript"> hbspt.cta.load(20244782, "c1a3ae43-c119-4d64-9b7c-c8a20ba7868d", {
      "useNewLoader": "true",
      "region": "na1"
      });
   </script>
</span>
<!-- end HubSpot Call-to-Action Code -->`;
export const NavbarAnnouncementBanner = () => {
  return <Stack alignItems="center" justifyContent="center" sx={{
    bgcolor: 'text.primary',
    pt: 1.5,
    pb: 1,
    px: 2,
    zIndex: 'drawer'
  }} data-sentry-element="Stack" data-sentry-component="NavbarAnnouncementBanner" data-sentry-source-file="NavbarAnnouncementBanner.tsx">
      <div dangerouslySetInnerHTML={{
      __html: hubspotSnippet
    }} />
    </Stack>;
};