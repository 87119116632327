'use client';

import { PropsWithChildren, useMemo } from 'react';
import { CssBaseline, Experimental_CssVarsProvider, experimental_extendTheme } from '@mui/material';
import { AppCacheProvider, EmotionCacheProviderProps } from '@mui/material-nextjs/v14-pagesRouter';
import { deepmerge } from '@mui/utils';
import { addSSRMatchMedia } from '@qb/base-theme/base-theme-utils';
import { qbThemeOptions } from '../../utils/qbTheme';

/**
 * https://mui.com/material-ui/integrations/nextjs/#typescript
 */
export const PagesRouterThemeProvider = ({
  children,
  emotionCache,
  isUserAgentDeviceMobile
}: PropsWithChildren<EmotionCacheProviderProps & {
  isUserAgentDeviceMobile: boolean;
}>) => {
  const themeWithMatchMedia = useMemo(() => {
    const options = deepmerge(qbThemeOptions, addSSRMatchMedia(isUserAgentDeviceMobile));
    return experimental_extendTheme(options);
  }, [isUserAgentDeviceMobile]);
  return <AppCacheProvider emotionCache={emotionCache} data-sentry-element="AppCacheProvider" data-sentry-component="PagesRouterThemeProvider" data-sentry-source-file="PagesRouterThemeProvider.tsx">
      <Experimental_CssVarsProvider theme={themeWithMatchMedia} data-sentry-element="Experimental_CssVarsProvider" data-sentry-source-file="PagesRouterThemeProvider.tsx">
        <CssBaseline data-sentry-element="CssBaseline" data-sentry-source-file="PagesRouterThemeProvider.tsx" />
        {children}
      </Experimental_CssVarsProvider>
    </AppCacheProvider>;
};