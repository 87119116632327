import React from 'react';
import { range } from 'lodash';
import { Stack, Typography } from '@mui/material';
import { isNullableOrEmptyArray } from '@/shared/types/util/TypeGuards';
import { PartCardHorizontal, PartCardHorizontalSkeleton } from '@/src/components/Common/PartCards/PartCardHorizontal';
import { ViewAllPartsButton } from '@/src/components/OmniSearch/components/ViewAllPartsButton';
import { usePartsAutocompleteRequest } from '@/src/components/OmniSearch/hooks/usePartsAutocompleteRequest';
import { NoPartResults } from './NoPartResults';
type PartQueryProps = {
  query: string;
};
export const PartQuery = ({
  query
}: PartQueryProps) => {
  const {
    arePartsLoading,
    parts
  } = usePartsAutocompleteRequest(query);
  const title = <Typography variant="h6" mb={2} color="text.secondary">
      Parts
    </Typography>;
  if (arePartsLoading) {
    return <>
        {title}
        <Stack spacing={1}>
          {range(4).map(index => <PartCardHorizontalSkeleton key={index} />)}
        </Stack>
      </>;
  }
  if (isNullableOrEmptyArray(parts)) {
    return <NoPartResults />;
  }
  return <>
      {title}
      {parts.map((part, index) => <PartCardHorizontal key={part.id} part={part} ga4data={{
      itemListName: 'omni_search_products',
      itemIndex: index
    }} />)}
      <ViewAllPartsButton query={query} data-sentry-element="ViewAllPartsButton" data-sentry-source-file="PartQuery.tsx" />
    </>;
};