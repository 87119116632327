import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useQueryClient } from '@tanstack/react-query';
import { useRouter, usePathname } from 'next/navigation';
import { webQueries } from '@qb/httpRequest/webQueries';
import { MessagesFromSP } from '@/shared/SalesPilotConstants';
import { useGetCurrentUserInfoRequest } from '@/src/components/Auth/hooks/useGetCurrentUserInfoRequest';
import { setUser } from '@/src/store/reducers/main';
export const useListenToSalesPilotMessages = () => {
  const queryClient = useQueryClient();
  const router = useRouter();
  const pathname = usePathname();
  const dispatch = useDispatch();
  const {
    data: userInfo
  } = useGetCurrentUserInfoRequest();
  useEffect(() => {
    const receiveMessage = (event: MessageEvent) => {
      if (event.data.action === MessagesFromSP.USER_LOGGED_IN) {
        queryClient.invalidateQueries(webQueries.user.getCurrentUserInfo());
        window?.salesPilotPostMessages?.collapse();
      }
      if (event.data.action === MessagesFromSP.USER_LOGGED_OUT) {
        queryClient.invalidateQueries(webQueries.user.getCurrentUserInfo());
      }
      if (event.data.action === MessagesFromSP.PENDING_USER_CREATED) {
        queryClient.invalidateQueries(webQueries.user.getCurrentUserInfo());
      }
      if (event.data.action === MessagesFromSP.USER_SIGNED_UP) {
        if (pathname === '/signup') {
          router.push('/pendingUser/addAddress');
        } else {
          dispatch(setUser(userInfo?.user));
          queryClient.invalidateQueries(webQueries.user.getCurrentUserInfo());
          router.push('/onboarding');
          window?.salesPilotPostMessages?.collapse();
        }
      }
    };
    window.addEventListener('message', receiveMessage);
    return () => {
      window.removeEventListener('message', receiveMessage);
    };
  }, [dispatch, pathname, queryClient, router, userInfo?.user]);
};