'use client';

import React from 'react';
import { DefaultSeo } from 'next-seo';
import { useRouter } from 'next/router';
import { siteMetadata, organizationJSONLD, websiteJSONLD } from '@/src/components/SEO/defaultSiteMetadata';
import { WEB_HOST } from '../../../WebConstants';
export const DefaultSeoConfig = () => {
  const router = useRouter();
  const canonicalURL = `${WEB_HOST}${(router.asPath === '/' ? '' : router.asPath).split('?')[0]}`;
  return <>
      <DefaultSeo title={undefined} titleTemplate={siteMetadata.title.template} defaultTitle={siteMetadata.title.default} description={siteMetadata.description} canonical={canonicalURL} dangerouslySetAllPagesToNoIndex={!siteMetadata.robots.index} dangerouslySetAllPagesToNoFollow={!siteMetadata.robots.follow} openGraph={siteMetadata.openGraph} additionalLinkTags={siteMetadata.icons.map(i => ({
      ...i,
      href: i.url
    }))} data-sentry-element="DefaultSeo" data-sentry-source-file="DefaultSEOConfig.tsx" />
      <script type="application/ld+json" dangerouslySetInnerHTML={{
      __html: JSON.stringify(organizationJSONLD)
    }} />
      <script type="application/ld+json" dangerouslySetInnerHTML={{
      __html: JSON.stringify(websiteJSONLD)
    }} />
    </>;
};