import { createSlice } from '@reduxjs/toolkit';
import { CompanyAgentsOption, LiveChatSettings } from '../types/types';

export type IntegrationsPageState = {
  liveChatSettings: null | LiveChatSettings;
  companyAgentsOptions: CompanyAgentsOption[];
  selectedCompanyAgents: CompanyAgentsOption[];
};

const getInitialStore = (): IntegrationsPageState => ({
  liveChatSettings: null,
  companyAgentsOptions: [],
  selectedCompanyAgents: [],
});

const integrationsPageSlice = createSlice({
  name: 'integrationsPage',
  initialState: getInitialStore(),
  reducers: {
    setLiveChatSettings: (store, { payload }) => ({
      ...store,
      liveChatSettings: payload,
    }),
    setCompanyAgentsOptions: (store, { payload }) => ({
      ...store,
      companyAgentsOptions: payload,
    }),
    setSelectedCompanyAgents: (store, { payload }) => ({
      ...store,
      selectedCompanyAgents: payload,
    }),
  },
});

export const {
  setLiveChatSettings,
  setCompanyAgentsOptions,
  setSelectedCompanyAgents,
} = integrationsPageSlice.actions;

export default integrationsPageSlice.reducer;
