'use client';

import { useCallback } from 'react';
import { Card, CardActionArea, Skeleton, Stack } from '@mui/material';
import { EcommerceItem } from '@qb/frontend/analytics/ga4Types';
import { recordSelectItemFromListForAnalytics } from '@qb/frontend/analytics/recordSelectItemFromListForAnalytics';
import { PartRelevantPartsResponse } from '@/shared/types/controllers/PartControllerTypes';
import { generatePartURL } from '@/shared/utils/URLs';
import { PartCardHorizontalInner } from '@/src/components/Common/PartCards/components/PartCardHorizontalInner';
type PartCardHorizontalProps = {
  part: PartRelevantPartsResponse['relevantParts'][0];
  onClick?: () => void;
  ga4data: {
    itemListName: EcommerceItem['item_list_name'];
    itemIndex: EcommerceItem['index'];
  };
};
export const PartCardHorizontal = ({
  part,
  onClick,
  ga4data
}: PartCardHorizontalProps) => {
  const href = generatePartURL(part.manufacturer.name, part.partNumber, part.id);
  const onPartView = useCallback(() => {
    if (onClick) {
      onClick();
    }
    recordSelectItemFromListForAnalytics({
      part,
      itemIndex: ga4data.itemIndex,
      itemListName: ga4data.itemListName
    });
  }, [onClick, part, ga4data.itemListName, ga4data.itemIndex]);
  return <Card sx={{
    borderWidth: '2px',
    borderColor: 'primary.12p',
    mb: {
      xs: 1,
      borderRadius: 0
    }
  }} data-sentry-element="Card" data-sentry-component="PartCardHorizontal" data-sentry-source-file="PartCardHorizontal.tsx">
      <CardActionArea onClick={onPartView} href={href} data-sentry-element="CardActionArea" data-sentry-source-file="PartCardHorizontal.tsx">
        <PartCardHorizontalInner part={part} data-sentry-element="PartCardHorizontalInner" data-sentry-source-file="PartCardHorizontal.tsx" />
      </CardActionArea>
    </Card>;
};
export const PartCardHorizontalSkeleton = () => <Card data-sentry-element="Card" data-sentry-component="PartCardHorizontalSkeleton" data-sentry-source-file="PartCardHorizontal.tsx">
    <Stack direction="row" spacing={2} width="100%" sx={{
    py: 1,
    px: 1.5,
    minWidth: 200
  }} data-sentry-element="Stack" data-sentry-source-file="PartCardHorizontal.tsx">
      <Skeleton variant="rectangular" width={50} height={50} data-sentry-element="Skeleton" data-sentry-source-file="PartCardHorizontal.tsx" />
      <Stack spacing={1} width="100%" data-sentry-element="Stack" data-sentry-source-file="PartCardHorizontal.tsx">
        <Skeleton width={100} data-sentry-element="Skeleton" data-sentry-source-file="PartCardHorizontal.tsx" />
        <Skeleton data-sentry-element="Skeleton" data-sentry-source-file="PartCardHorizontal.tsx" />
        <Skeleton width={60} data-sentry-element="Skeleton" data-sentry-source-file="PartCardHorizontal.tsx" />
      </Stack>
    </Stack>
  </Card>;