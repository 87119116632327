'use client';

import { useInterval } from 'react-use';
import { BUILD_ID as BUILD_ID_ON_CLIENT } from '../../../WebConstants';

const DELAY = 30 * 60 * 1000; // in ms

export function DeployRefreshManager() {
  useInterval(async () => {
    try {
      const response = await fetch('/api/build-id');
      const { buildId: buildIdOnServer } = await response.json();

      if (
        buildIdOnServer &&
        BUILD_ID_ON_CLIENT &&
        buildIdOnServer !== BUILD_ID_ON_CLIENT
      ) {
        window.location.reload();
      }
    } catch (e) {
      console.error(e);
    }
  }, DELAY);

  return null;
}
