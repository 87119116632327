'use client';

import { useState, useEffect, PropsWithChildren, useCallback, memo } from 'react';
import dynamic from 'next/dynamic';
import { Stack, Box } from '@mui/material';
import { useIsMobile } from '@qb/frontend/hooks/mediaQueries';
import { NAVBAR_HEIGHT } from '@/src/components/Layouts/AppLayout/navbarConstants';
import { SidebarToggleIcon } from './components/SidebarToggleIcon';
const SidebarNav = dynamic(() => import('./components/SidebarNav').then(mod => mod.SidebarNav));
const SIZE_SMALL = 68;
const SIZE_LARGE = 190;
const SIZE_HIDDEN = 0;
const PAGES_WITH_MINI_SIDEBAR = ['/conversation', '/search/partCategory'];
const PAGES_WITH_NO_SIDEBAR = ['/cart/checkout'];
const getMaxWidth = (isMobile: boolean, pathname: string) => {
  if (isMobile || PAGES_WITH_NO_SIDEBAR.some(page => pathname.startsWith(page))) {
    return SIZE_HIDDEN;
  }
  if (PAGES_WITH_MINI_SIDEBAR.some(page => pathname.startsWith(page))) {
    return SIZE_SMALL;
  }
  return SIZE_LARGE;
};
const Wrapper = ({
  children,
  width
}: PropsWithChildren<{
  width: number;
}>) => <Stack component="aside" data-cy="SidebarDesktopContainer" sx={{
  display: ['none', 'flex'],
  transition: ({
    transitions
  }) => transitions.create(['width']),
  width: width,
  bgcolor: 'common.white',
  boxShadow: 1,
  zIndex: 1,
  position: 'relative',
  flexShrink: 0,
  '.separate-settings-navbar': {
    /*
     * Because the navbar can have different heights between static and sticky position,
     * we can't set the sidebar height correctly in order to place the settings item at the bottom of the page.
     * so using position fixed solves this issue. it's the least hacky way to do it.
     */
    transition: ({
      transitions
    }) => transitions.create(['width']),
    width: width,
    position: 'fixed',
    bottom: 0,
    left: 0
  }
}} data-sentry-element="Stack" data-sentry-component="Wrapper" data-sentry-source-file="SidebarDesktopContainer.tsx">
    {children}
  </Stack>;
const StickyWrapper = ({
  children
}: PropsWithChildren) => <Box data-cy="StickySidebarDesktopContainer" sx={{
  overflow: 'hidden',
  minWidth: SIZE_SMALL,
  position: 'sticky',
  top: NAVBAR_HEIGHT,
  left: 0
}} data-sentry-element="Box" data-sentry-component="StickyWrapper" data-sentry-source-file="SidebarDesktopContainer.tsx">
    {children}
  </Box>;
const SidebarDesktopContainerInternal = ({
  pathname
}: {
  pathname: string;
}) => {
  const isMobile = useIsMobile();
  const maxWidth = getMaxWidth(isMobile, pathname);
  const [currentWidth, setCurrentWidth] = useState(maxWidth);
  useEffect(() => setCurrentWidth(maxWidth), [maxWidth]);
  const toggleSidebar = useCallback(() => {
    setCurrentWidth(currentWidth === SIZE_SMALL ? SIZE_LARGE : SIZE_SMALL);
  }, [currentWidth]);
  const isSmallSize = currentWidth === SIZE_SMALL;
  const isLargeSize = currentWidth === SIZE_LARGE;
  const isHidden = currentWidth === SIZE_HIDDEN;
  if (isHidden) {
    return null;
  }
  return <Wrapper width={currentWidth} data-sentry-element="Wrapper" data-sentry-component="SidebarDesktopContainerInternal" data-sentry-source-file="SidebarDesktopContainer.tsx">
      <StickyWrapper data-sentry-element="StickyWrapper" data-sentry-source-file="SidebarDesktopContainer.tsx">
        <SidebarNav isSettingsLinkSeparate isSmallSize={isSmallSize} data-sentry-element="SidebarNav" data-sentry-source-file="SidebarDesktopContainer.tsx">
          <SidebarToggleIcon isOpen={isLargeSize} onClick={toggleSidebar} data-sentry-element="SidebarToggleIcon" data-sentry-source-file="SidebarDesktopContainer.tsx" />
        </SidebarNav>
      </StickyWrapper>
    </Wrapper>;
};
export const SidebarDesktopContainer = memo(SidebarDesktopContainerInternal);