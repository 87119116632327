import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@/src/store/rootReducer';

export type PaymentModeType = 'creditCard' | 'onTerms';

export enum CheckoutStepsEnum {
  shippingAddress = 0,
  shippingMethod = 1,
  payment = 2,
  review = 3,
}

type CheckoutPageState = {
  activeStep: CheckoutStepsEnum;
  shippingAddressId: number | null;
  billingAddressId: number | null;
  paymentMode: PaymentModeType;
  paymentTermId: number | null;
  paymentTermDuration: number | null;
  paymentCardId: string | null;
  /** @see https://stripe.com/docs/payments/3d-secure */
  paymentIntentIDToReuse: string | null;
  resellerCertificateId: number | null;
  shippingAccountId: number | null;
  isShippingAccountFormOpen: boolean;
  poNumber: string;
  shippingInstructions: string;
  shippingMethod: string | null;
  errorMessage: string | null;
  activeCouponCodeID: number | null;
};

export const initialState: CheckoutPageState = {
  activeStep: CheckoutStepsEnum.shippingAddress,
  shippingAddressId: null,
  billingAddressId: null,
  paymentTermId: null,
  paymentTermDuration: null,
  paymentCardId: null,
  paymentMode: 'onTerms',
  paymentIntentIDToReuse: null,
  resellerCertificateId: null,
  shippingAccountId: null,
  isShippingAccountFormOpen: false,
  poNumber: '',
  shippingInstructions: '',
  shippingMethod: null,
  errorMessage: null,
  activeCouponCodeID: null,
};

const checkoutPage = createSlice({
  name: 'checkoutPage',
  initialState,
  reducers: {
    setShippingAddressId: (store, { payload }: PayloadAction<number>) => ({
      ...store,
      shippingAddressId: payload,
    }),
    setBillingAddressId: (store, { payload }: PayloadAction<number>) => ({
      ...store,
      billingAddressId: payload,
    }),
    setPaymentCardId: (store, { payload }: PayloadAction<string | null>) => ({
      ...store,
      paymentCardId: payload,
    }),
    setPaymentMode: (store, { payload }: PayloadAction<PaymentModeType>) => ({
      ...store,
      paymentMode: payload,
    }),
    setPaymentTermId: (
      store,
      {
        payload,
      }: PayloadAction<{ id: number | null; duration: number | null }>,
    ) => ({
      ...store,
      paymentTermId: payload.id,
      paymentTermDuration: payload.duration,
    }),
    setPaymentIntentIDToReuse: (store, { payload }: PayloadAction<string>) => ({
      ...store,
      paymentIntentIDToReuse: payload,
    }),
    setResellerCertificateId: (
      store,
      { payload }: PayloadAction<number | null>,
    ) => ({
      ...store,
      resellerCertificateId: payload,
    }),
    setShippingAccountId: (
      store,
      { payload }: PayloadAction<number | null>,
    ) => ({
      ...store,
      shippingAccountId: payload,
    }),
    setIsShippingAccountFormOpen: (
      store,
      { payload }: PayloadAction<boolean>,
    ) => ({
      ...store,
      isShippingAccountFormOpen: payload,
    }),
    setPONumber: (store, { payload }: PayloadAction<string>) => ({
      ...store,
      poNumber: payload,
    }),
    setShippingInstructions: (store, { payload }: PayloadAction<string>) => ({
      ...store,
      shippingInstructions: payload,
    }),
    setShippingMethod: (store, { payload }: PayloadAction<string>) => ({
      ...store,
      shippingMethod: payload,
    }),
    goToShippingAddressStep: (store) => ({
      ...store,
      activeStep: CheckoutStepsEnum.shippingAddress,
    }),
    goToShippingMethodStep: (store) => ({
      ...store,
      activeStep: CheckoutStepsEnum.shippingMethod,
    }),
    goToPaymentStep: (store) => ({
      ...store,
      activeStep: CheckoutStepsEnum.payment,
    }),
    goToReviewStep: (store) => ({
      ...store,
      activeStep: CheckoutStepsEnum.review,
    }),
    goToStep: (store, { payload }: PayloadAction<CheckoutStepsEnum>) => ({
      ...store,
      activeStep: payload,
    }),
    setErrorMessage: (store, { payload }: PayloadAction<string | null>) => ({
      ...store,
      errorMessage: payload,
    }),
    setActiveCouponCodeID: (store, { payload }: PayloadAction<number>) => ({
      ...store,
      activeCouponCodeID: payload,
    }),
    resetCheckoutPageState: () => initialState,
  },
});

export const {
  setShippingAddressId,
  setBillingAddressId,
  setPaymentCardId,
  setPaymentTermId,
  setPaymentMode,
  setPaymentIntentIDToReuse,
  setResellerCertificateId,
  setShippingAccountId,
  setPONumber,
  setShippingInstructions,
  goToShippingAddressStep,
  goToShippingMethodStep,
  goToReviewStep,
  goToStep,
  goToPaymentStep,
  setShippingMethod,
  setIsShippingAccountFormOpen,
  setErrorMessage,
  resetCheckoutPageState,
  setActiveCouponCodeID,
} = checkoutPage.actions;

export const shippingAddressIdSelector = ({
  checkoutPage: { shippingAddressId },
}: RootState) => shippingAddressId;

export const billingAddressIdSelector = ({
  checkoutPage: { billingAddressId },
}: RootState) => billingAddressId;

export const activeStepSelector = ({
  checkoutPage: { activeStep },
}: RootState) => activeStep;

export const paymentCardIdSelector = ({
  checkoutPage: { paymentCardId },
}: RootState) => paymentCardId;

export const paymentModeSelector = ({
  checkoutPage: { paymentMode },
}: RootState) => paymentMode;

export const resellerCertificateIdSelector = ({
  checkoutPage: { resellerCertificateId },
}: RootState) => resellerCertificateId;

export const shippingAccountIdSelector = ({
  checkoutPage: { shippingAccountId },
}: RootState) => shippingAccountId;

export const isShippingAccountFormOpenSelector = ({
  checkoutPage: { isShippingAccountFormOpen },
}: RootState) => isShippingAccountFormOpen;

export const isUsingOwnCarrierSelector = ({
  checkoutPage: { shippingAccountId, isShippingAccountFormOpen },
}: RootState): boolean =>
  shippingAccountId !== null || isShippingAccountFormOpen;

export const poNumberSelector = ({ checkoutPage: { poNumber } }: RootState) =>
  poNumber;

export const shippingMethodSelector = ({
  checkoutPage: { shippingMethod },
}: RootState) => shippingMethod;

export const shippingInstructionsSelector = ({
  checkoutPage: { shippingInstructions },
}: RootState) => shippingInstructions;

export const errorMessageSelector = ({
  checkoutPage: { errorMessage },
}: RootState) => errorMessage;

export const activeCouponCodeIDSelector = ({
  checkoutPage: { activeCouponCodeID },
}: RootState) => activeCouponCodeID;

export default checkoutPage.reducer;
