import React from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Typography, Stack } from '@mui/material';
import { FastShippingChip } from './FastShippingChip';
type StockFoundProps = {
  stock: number;
  showFastShipping?: boolean;
};
export const StockFound = ({
  stock,
  showFastShipping = true
}: StockFoundProps) => {
  return <Stack alignItems="flex-end" spacing={1} data-sentry-element="Stack" data-sentry-component="StockFound" data-sentry-source-file="StockFound.tsx">
      <Stack direction="row" spacing={0.5} alignItems="center" data-sentry-element="Stack" data-sentry-source-file="StockFound.tsx">
        <CheckCircleIcon sx={{
        fontSize: '14px'
      }} color="success" data-sentry-element="CheckCircleIcon" data-sentry-source-file="StockFound.tsx" />
        <Typography variant="bodyMediumPrimary" color="text.primary" data-sentry-element="Typography" data-sentry-source-file="StockFound.tsx">
          STOCK: {stock}
        </Typography>
      </Stack>
      {showFastShipping && <FastShippingChip />}
    </Stack>;
};