import Image from 'next/image';
import { Stack, StackProps } from '@mui/material';
import americanExpress from '../assets/americanExpress.svg';
import mastercard from '../assets/mastercard.svg';
import visa from '../assets/visa.svg';
export function CreditCards({
  sx
}: Pick<StackProps, 'sx'>) {
  return <Stack direction="row" spacing={1} sx={sx} data-sentry-element="Stack" data-sentry-component="CreditCards" data-sentry-source-file="CreditCards.tsx">
      <Image src={visa} width={35} height={24} alt="Visa" data-sentry-element="Image" data-sentry-source-file="CreditCards.tsx" />
      <Image src={mastercard} width={35} height={24} alt="Mastercard" data-sentry-element="Image" data-sentry-source-file="CreditCards.tsx" />
      <Image src={americanExpress} width={35} height={24} alt="American Express" data-sentry-element="Image" data-sentry-source-file="CreditCards.tsx" />
    </Stack>;
}