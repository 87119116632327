'use client';

import { useRef, useState } from 'react';
import dynamic from 'next/dynamic';
import { Search } from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';
import { useIsUnderBreakpoint } from '@qb/frontend/hooks/mediaQueries';
import { OmniSearchDesktopPopup } from '@/src/components/OmniSearch/components/OmniSearchDesktopPopup/OmniSearchDesktopPopup';
import { useDebouncedQuery } from '@/src/components/OmniSearch/hooks/useDebouncedQuery';
import { useIsUserTyping } from '@/src/components/OmniSearch/hooks/useIsUserTyping';
import { OmniSearchInput, OmniSearchInputG4AEventsProps } from './components/OmniSearchInput';
import { useRegisterOmniSearchHandlers } from './hooks/useRegisterOmniSearchHandlers';
const OmniSearchMobileDrawer = dynamic(() => import('./components/OmniSearchMobileDrawer/OmniSearchMobileDrawer').then(mod => mod.OmniSearchMobileDrawer), {
  ssr: false
});
export const NavbarOmniSearch = ({
  searchInputName
}: OmniSearchInputG4AEventsProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const {
    query,
    setQuery,
    debouncedQuery
  } = useDebouncedQuery('', 300);
  const isUserTyping = useIsUserTyping(query);
  const ref = useRef<HTMLElement | null>(null);
  const inputRef = useRegisterOmniSearchHandlers(isOpen, setIsOpen);
  const shouldShowMobile = useIsUnderBreakpoint('md');
  if (shouldShowMobile) {
    return <>
        <IconButton aria-label="open search" onClick={() => setIsOpen(true)}>
          <Search sx={{
          color: 'text.primary'
        }} />
        </IconButton>
        <OmniSearchMobileDrawer searchInputName={searchInputName} isVisible={isOpen} setIsVisible={setIsOpen} />
      </>;
  }
  return <>
      <Box ref={ref} sx={{
      width: 368
    }} data-sentry-element="Box" data-sentry-source-file="NavbarOmniSearch.tsx">
        <OmniSearchInput sx={{
        display: {
          xs: 'none',
          md: 'flex'
        },
        '.MuiOutlinedInput-notchedOutline': {
          borderColor: 'primary.main'
        }
      }} magicIconSx={{
        width: 40
      }} searchInputName={searchInputName} inputRef={inputRef} value={query} onFocus={() => setIsOpen(true)} onChange={e => setQuery(e.target.value)} data-sentry-element="OmniSearchInput" data-sentry-source-file="NavbarOmniSearch.tsx" />
      </Box>
      <OmniSearchDesktopPopup query={debouncedQuery} isUserTyping={isUserTyping} ref={ref} isOpen={isOpen} setIsOpen={setIsOpen} marginTop={4} data-sentry-element="OmniSearchDesktopPopup" data-sentry-source-file="NavbarOmniSearch.tsx" />
    </>;
};