import dynamic from 'next/dynamic';
import { Box, SxProps } from '@mui/material';
import { mergeSx } from '@qb/frontend/utils/mergeSx';
const animations = {
  matrix: dynamic(() => import('./components/MascotAnimationMatrix').then(mod => mod.MascotAnimationMatrix), {
    ssr: false
  }),
  monocle: dynamic(() => import('./components/MascotAnimationMonocle').then(mod => mod.MascotAnimationMonocle), {
    ssr: false
  }),
  friendly: dynamic(() => import('./components/MascotAnimationFriendly').then(mod => mod.MascotAnimationFriendly), {
    ssr: false
  })
};
type Props = {
  variant: 'matrix' | 'monocle' | 'friendly';
  /* Renders all versions and switches using display css property. Prevents flicker
   * that happens when used with conditional rendering*/
  smoothSwitch?: boolean;
  sx?: SxProps;
};
export const MascotAnimation = ({
  variant,
  smoothSwitch = false,
  sx = {}
}: Props) => {
  const AnimationComponent = animations[variant];
  const boxSx = mergeSx({
    width: 130,
    height: 180
  }, sx);
  if (smoothSwitch) {
    return <Box sx={boxSx}>
        {Object.entries(animations).map(([key, Component]) => <Box key={key} display={key === variant ? 'block' : 'none'}>
            <Component />
          </Box>)}
      </Box>;
  }
  return <Box sx={boxSx} data-sentry-element="Box" data-sentry-component="MascotAnimation" data-sentry-source-file="MascotAnimation.tsx">
      <AnimationComponent data-sentry-element="AnimationComponent" data-sentry-source-file="MascotAnimation.tsx" />
    </Box>;
};