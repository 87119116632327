'use client';

import { useState, useCallback } from 'react';
import dynamic from 'next/dynamic';
import MenuIcon from '@mui/icons-material/Menu';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import { IconButton, Drawer, Stack } from '@mui/material';
import { LogoWithTagLine } from '@/src/components/Layouts/LogoWithTagLine/LogoWithTagLine';
import { SidebarAuthNav } from './components/SidebarAuthNav';
import { SocialMediaSet } from './components/SocialMediaSet';
export const SidebarNav = dynamic(() => import('./components/SidebarNav').then(mod => mod.SidebarNav));
export const SidebarMobileContainer = () => {
  const [isOpen, setIsOpen] = useState(false);
  const handleSetClosed = useCallback(() => setIsOpen(false), []);
  return <>
      <IconButton sx={{
      display: ['flex', 'flex', 'none']
    }} onClick={() => setIsOpen(true)} title="Open Menu" aria-label="Open Menu" data-sentry-element="IconButton" data-sentry-source-file="SidebarMobileContainer.tsx">
        <MenuIcon data-sentry-element="MenuIcon" data-sentry-source-file="SidebarMobileContainer.tsx" />
      </IconButton>
      <Drawer open={isOpen} PaperProps={{
      sx: {
        width: '100%',
        maxWidth: 300
      }
    }} data-sentry-element="Drawer" data-sentry-source-file="SidebarMobileContainer.tsx">
        <Stack direction="row" justifyContent="space-between" p={1} data-sentry-element="Stack" data-sentry-source-file="SidebarMobileContainer.tsx">
          <LogoWithTagLine height={[40, 40, 45]} data-sentry-element="LogoWithTagLine" data-sentry-source-file="SidebarMobileContainer.tsx" />
          <IconButton title="Close Menu" aria-label="Close Menu" sx={{
          display: ['flex', 'flex', 'none']
        }} onClick={handleSetClosed} data-sentry-element="IconButton" data-sentry-source-file="SidebarMobileContainer.tsx">
            <MenuOpenIcon data-sentry-element="MenuOpenIcon" data-sentry-source-file="SidebarMobileContainer.tsx" />
          </IconButton>
        </Stack>
        <SidebarNav onClick={handleSetClosed} data-sentry-element="SidebarNav" data-sentry-source-file="SidebarMobileContainer.tsx" />
        <SidebarAuthNav onClick={handleSetClosed} data-sentry-element="SidebarAuthNav" data-sentry-source-file="SidebarMobileContainer.tsx" />
        <SocialMediaSet data-sentry-element="SocialMediaSet" data-sentry-source-file="SidebarMobileContainer.tsx" />
      </Drawer>
    </>;
};