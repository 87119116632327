import { forwardRef } from 'react';
import { Inter } from 'next/font/google';
import NextLink, { LinkProps as NextLinkProps } from 'next/link';
import { createBaseThemeOptions } from '@qb/base-theme/base-theme';
import { integrateThemeWithRouter, addSharpCornersStyle, mergeCssVarsThemeOptions } from '@qb/base-theme/base-theme-utils';
const LinkBehaviour = forwardRef<HTMLAnchorElement, NextLinkProps>(function LinkBehaviour(props, ref) {
  return <NextLink ref={ref} {...props} />;
});
const inter = Inter({
  subsets: ['latin']
});
const fontFamily = `${inter.style.fontFamily},Helvetica Neue,Helvetica,Arial,sans-serif`;
const baseTheme = createBaseThemeOptions({}, fontFamily);
export const qbThemeOptions = mergeCssVarsThemeOptions(baseTheme, integrateThemeWithRouter(LinkBehaviour), addSharpCornersStyle, {
  components: {
    MuiButton: {
      defaultProps: {
        disableElevation: true
      }
    },
    MuiTableCell: {
      defaultProps: {},
      styleOverrides: {
        head: {
          '&:first-of-type': {
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0
          },
          '&:last-of-type': {
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0
          }
        }
      }
    },
    MuiContainer: {
      defaultProps: {
        maxWidth: 'xl'
      }
    },
    MuiBackdrop: {
      styleOverrides: {
        invisible: {
          background: 'unset'
        },
        root: {
          background: 'rgba(141, 154, 171, 0.5)'
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        colorAccent: {
          color: 'var(--mui-palette-text-primary)'
        }
      },
      defaultProps: {
        size: 'small'
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiInputBase-root': {
            backgroundColor: 'var(--mui-palette-common-white)'
          }
        }
      }
    }
  }
});