import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { Box, IconButton, Typography } from '@mui/material';
export const SocialMediaSet = () => <>
    <Box sx={{
    display: 'flex',
    pl: 1.5
  }} data-sentry-element="Box" data-sentry-source-file="SocialMediaSet.tsx">
      <IconButton href="https://www.linkedin.com/company/quotebeam" title="Go to Quotebeam LinkedIn account" target="_blank" rel="noreferrer" data-sentry-element="IconButton" data-sentry-source-file="SocialMediaSet.tsx">
        <LinkedInIcon htmlColor="#0e76a8" sx={{
        fontSize: 45
      }} data-sentry-element="LinkedInIcon" data-sentry-source-file="SocialMediaSet.tsx" />
      </IconButton>
      <IconButton href="https://www.facebook.com/QuoteBeam" title="Go to Quotebeam Facebook account" target="_blank" rel="noreferrer" data-sentry-element="IconButton" data-sentry-source-file="SocialMediaSet.tsx">
        <FacebookIcon htmlColor="#3b5998" sx={{
        fontSize: 45
      }} data-sentry-element="FacebookIcon" data-sentry-source-file="SocialMediaSet.tsx" />
      </IconButton>
    </Box>
    <Typography variant="bodyMedium" sx={{
    mb: 2,
    mt: 1,
    pl: 3,
    pr: 1
  }} data-sentry-element="Typography" data-sentry-source-file="SocialMediaSet.tsx">
      Equipment Manufacturers, Suppliers, Parts Manufacturers. All in one place.
    </Typography>
  </>;