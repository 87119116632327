'use client';

import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import dynamic from 'next/dynamic';
import { useRouter, usePathname } from 'next/navigation';
import { Box } from '@mui/material';
import { EventEmitter } from '@qb/frontend/utils/EventEmitter';
import { SocketGetRequest } from '@qb/httpRequest/SocketRequest';
import { WebAPIFullURL, BAD_REQUEST_EVENT, FORBIDDEN_ERROR_CODE, NOT_FOUND_ERROR_CODE, UNAUTHORIZED_ERROR_CODE } from '@qb/httpRequest/constants';
import { GetAppResponse } from '@/shared/types/controllers/AppControllerTypes';
import { PartCategoryFooterCategoriesResponse } from '@/shared/types/controllers/PartCategoryControllerTypes';
import CustomModalWrapper from '@/src/components/Common/CustomModal/CustomModalWrapper';
import { RightDrawerWrapper } from '@/src/components/Common/RightDrawer/RightDrawerWrapper';
import ErrorBoundary from '@/src/components/Components/ErrorBoundary';
import { SidebarDesktopContainer } from '@/src/components/Layouts/AppLayout/Sidebar/SidebarDesktopContainer';
import { useWarnOnContextWidthLargerThanViewport } from '@/src/components/Layouts/AppLayout/hooks/useWarnOnContextWidthLargerThanViewport';
import { NAVBAR_HEIGHT } from '@/src/components/Layouts/AppLayout/navbarConstants';
import { isAuthenticatedSelector } from '@/src/store/reducers/main';
import { Footer } from './Footer/Footer';
import { Navbar } from './Navbar/Navbar';
import { useListenToSalesPilotMessages } from './hooks/useListenToSalesPilotMessages';

// Used for special messages, for example Thanksgiving, Christmas
// import { InfoBanner } from '@/src/components/Common/InfoBanner';

const NotFoundPage = dynamic(() => import('@/src/components/Error/NotFoundPage').then(mod => mod.NotFoundPage));
type AppLayoutProps = {
  children: React.ReactNode;
  backgroundColor?: string;
  footerCategories: PartCategoryFooterCategoriesResponse['partCategories'];
  hasAuthenticatedLayoutCookie: boolean;
  appDetails?: GetAppResponse;
};
export const AppLayout = ({
  children,
  backgroundColor,
  hasAuthenticatedLayoutCookie,
  footerCategories,
  appDetails
}: AppLayoutProps) => {
  const pathname = usePathname();
  const router = useRouter();
  const isAuthenticatedUser = useSelector(isAuthenticatedSelector);
  const [overrideContent, setOverrideContent] = useState<React.ReactNode>(null);
  useListenToSalesPilotMessages();
  useWarnOnContextWidthLargerThanViewport();
  useEffect(() => {
    const badRequestEventHandler = (status: number) => {
      if (status === FORBIDDEN_ERROR_CODE || status === NOT_FOUND_ERROR_CODE) {
        setOverrideContent(<NotFoundPage />);
      }
      if (status === UNAUTHORIZED_ERROR_CODE) {
        router.replace('/login');
      }
    };
    EventEmitter.subscribe(BAD_REQUEST_EVENT, badRequestEventHandler);
    return () => {
      EventEmitter.unsubscribe(BAD_REQUEST_EVENT, badRequestEventHandler);
    };
  }, [router]);
  useEffect(() => {
    if (isAuthenticatedUser) {
      SocketGetRequest(`${WebAPIFullURL}/subscribeToCompanySocket`);
    }
  }, [isAuthenticatedUser]);
  const isSidebarVisible = hasAuthenticatedLayoutCookie || isAuthenticatedUser;
  return <Box display="grid" /* Solves the issue of sticky-navbar not stretching with the main content is over-sized for mobile */ data-sentry-element="Box" data-sentry-component="AppLayout" data-sentry-source-file="AppLayout.tsx">
      <Navbar appDetails={appDetails} data-sentry-element="Navbar" data-sentry-source-file="AppLayout.tsx" />
      <Box display="flex" data-sentry-element="Box" data-sentry-source-file="AppLayout.tsx">
        {isSidebarVisible && <SidebarDesktopContainer pathname={pathname} />}
        <Box flexGrow={1} data-cy="AppLayoutInnerContainer" data-sentry-element="Box" data-sentry-source-file="AppLayout.tsx">
          {/** Use for special messages, for example Thanksgiving, Christmas.. */}
          {/*<InfoBanner />*/}
          <ErrorBoundary data-sentry-element="ErrorBoundary" data-sentry-source-file="AppLayout.tsx">
            <Box data-cy="AppLayoutMain" component="main" sx={{
            minHeight: `calc(100vh - ${NAVBAR_HEIGHT}px)`,
            width: '100%',
            backgroundColor: backgroundColor ?? 'background.default'
          }} data-sentry-element="Box" data-sentry-source-file="AppLayout.tsx">
              {overrideContent ? overrideContent : children}
            </Box>
          </ErrorBoundary>
          {!pathname.startsWith('/conversation') && <Footer footerCategories={footerCategories} />}
        </Box>
      </Box>
      <RightDrawerWrapper data-sentry-element="RightDrawerWrapper" data-sentry-source-file="AppLayout.tsx" />
      <CustomModalWrapper router={router} data-sentry-element="CustomModalWrapper" data-sentry-source-file="AppLayout.tsx" />
    </Box>;
};