import { useQuery } from '@tanstack/react-query';
import { hoursToMilliseconds, minutesToMilliseconds } from 'date-fns';
import { webQueries } from '@qb/httpRequest/webQueries';

const SEARCH_CATEGORIES_LIMIT = 15;

export const useSearchCategoriesRequest = () => {
  const { data: searchCategories, isLoading: areSearchCategoriesLoading } =
    useQuery({
      ...webQueries.partCategory.searchCategories,
      staleTime: minutesToMilliseconds(10),
      cacheTime: hoursToMilliseconds(1),
      select: (data) => data.slice(0, SEARCH_CATEGORIES_LIMIT),
    });

  return {
    searchCategories,
    areSearchCategoriesLoading,
  };
};
