import { useDispatch } from 'react-redux';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useRouter } from 'next/navigation';
import { disconnectSocket } from '@qb/httpRequest/SocketRequest';
import { webQueries } from '@qb/httpRequest/webQueries';
import { useHasAuthenticatedLayoutCookie } from '@/src/components/Auth/hooks/useHasAuthenticatedLayoutCookie';
import { fetchFeatureFlips, setUser } from '@/src/store/reducers/main';
export const LOGOUT_EVENT_NAME = 'logout-event';
export const useLogoutRequest = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const router = useRouter();
  const {
    removeHasAuthenticatedLayoutCookie
  } = useHasAuthenticatedLayoutCookie();
  return useMutation({
    ...webQueries.auth.logout,
    onSuccess: () => {
      removeHasAuthenticatedLayoutCookie();
      dispatch(setUser(null));
      dispatch(fetchFeatureFlips());
      queryClient.removeQueries();
      disconnectSocket();
      localStorage.setItem(LOGOUT_EVENT_NAME, 'logout_' + Date.now());
      window?.salesPilotPostMessages?.notifyUserHasLoggedOut();
      router.push('/login');
    }
  });
};