import { WithContext, Organization, WebSite } from 'schema-dts';
import { ENV_NAME, WEB_HOST } from '../../../WebConstants';

export const SITE_NAME = 'Quotebeam';

export const siteMetadata = {
  metadataBase: new URL(WEB_HOST || ''),
  title: {
    default: 'Quotebeam - Equipment Manufacturing Platform',
    template: `%s | ${SITE_NAME}`,
  },
  description:
    'Quotebeam is a platform where engineering and procurement teams can find parts and collaborate with suppliers and manufacturers, all in one place.',
  robots: {
    follow: ENV_NAME === 'production',
    index: ENV_NAME === 'production',
  },
  keywords: [
    'industrial automation',
    'instant quotes',
    'automation parts search',
    'automation parts specs',
    'automation parts catalog',
    'procurement',
    'BOM Sourcing',
    'MRO',
    'b2b marketplace',
    'motion control and sourcing',
  ],
  openGraph: {
    type: 'website',
    locale: 'en_US',
    url: WEB_HOST,
    siteName: SITE_NAME,
    images: [
      {
        url: `${WEB_HOST}/images/Common/QB-opengraph-image.png`,
        width: 1200,
        height: 630,
        alt: 'Quotebeam Image',
        type: 'image/png',
      },
    ],
  },
  icons: [
    {
      rel: 'apple-touch-icon',
      type: 'image/png',
      sizes: '180x180',
      url: '/favicons/apple-touch-icon.png',
    },
    {
      rel: 'icon',
      type: 'image/png',
      sizes: '16x16',
      url: '/favicons/favicon-16x16.png',
    },
    {
      rel: 'icon',
      type: 'image/png',
      sizes: '32x32',
      url: '/favicons/favicon-32x32.png',
    },
    {
      rel: 'mask-icon',
      url: '/favicons/safari-pinned-tab.svg',
      color: '#5BBAD5',
    },
    {
      rel: 'manifest',
      type: 'application/manifest+json',
      url: '/manifest.json',
    },
    {
      rel: 'manifest',
      type: 'application/manifest+json',
      url: '/site.webmanifest',
    },
  ],
};

export const organizationJSONLD: WithContext<Organization> = {
  '@context': 'https://schema.org',
  '@type': 'OnlineBusiness',
  name: SITE_NAME,
  legalName: 'Quotebeam Inc.',
  description: siteMetadata.description,
  url: WEB_HOST,
  logo: `${WEB_HOST}/images/Common/logo-for-opengraph.png`,
  image: `${WEB_HOST}/images/Common/QB-opengraph-image.png`,
  foundingDate: '2021',
  email: 'customer@quotebeam.com',
  telephone: '+747-302-4552',
  address: {
    '@type': 'PostalAddress',
    streetAddress: '166 Geary St',
    addressLocality: 'San Francisco',
    addressRegion: 'CA',
    postalCode: '94108',
    addressCountry: {
      '@type': 'Country',
      name: 'US',
    },
  },
  contactPoint: {
    '@type': 'ContactPoint',
    contactType: 'sales',
    telephone: '+747-302-4552',
    email: 'customer@quotebeam.com',
  },
  sameAs: ['https://www.linkedin.com/company/quotebeam/'],
};

export const websiteJSONLD: WithContext<WebSite> = {
  '@context': 'https://schema.org',
  '@type': 'WebSite',
  url: WEB_HOST,
  potentialAction: {
    '@type': 'SearchAction',
    target: {
      '@type': 'EntryPoint',
      urlTemplate: `${WEB_HOST}/search/part?query={search_term_string}`,
    },
    // @ts-expect-error The type in the library is `query` but Google expects `query-input`
    'query-input': 'required name=search_term_string',
  },
};
