import { useDispatch } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import Smartlook from 'smartlook-client';
import { setGA4CurrentUser } from '@qb/frontend/analytics/ga4';
import { webQueries } from '@qb/httpRequest/webQueries';
import { ENV_NAME } from '@/WebConstants';
import { BOT_STRING } from '@/shared/Constants';
import { useHasAuthenticatedLayoutCookie } from '@/src/components/Auth/hooks/useHasAuthenticatedLayoutCookie';
import { setUser, setSessionID } from '@/src/store/reducers/main';

export const useGetCurrentUserInfoRequest = () => {
  const dispatch = useDispatch();
  const {
    setHasAuthenticatedLayoutCookie,
    removeHasAuthenticatedLayoutCookie,
  } = useHasAuthenticatedLayoutCookie();

  return useQuery({
    ...webQueries.user.getCurrentUserInfo(),
    onSuccess: ({ user, sessionId }) => {
      if (user) {
        setHasAuthenticatedLayoutCookie();
      } else {
        removeHasAuthenticatedLayoutCookie();
      }
      dispatch(setUser(user));
      dispatch(setSessionID(user));
      setGA4CurrentUser(user);

      if (ENV_NAME === 'production' && user) {
        Smartlook.identify(user?.id ?? sessionId ?? BOT_STRING, {
          sessionId: sessionId || BOT_STRING,
          firstName: user?.firstName,
          lastName: user?.lastName,
          companyName: user?.company?.name,
        });
      }
    },
  });
};
