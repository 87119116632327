import { combineReducers } from 'redux';
import checkoutPage from '../components/Checkout/store/reducer';
import integrationsPage from '../components/Company/Settings/IntegrationsPage/store/reducer';
import conversationPage from '../components/Conversation/store/reducer';
import orderDisplayPage from '../components/Order/OrderDisplayPage/store/reducer';
import QuoteDisplayPageStore from './reducers/QuoteDisplayPageStore';
import main from './reducers/main';

const rootReducer = combineReducers({
  main,
  conversationPage,
  checkoutPage,
  orderDisplayPage,
  integrationsPage,
  QuoteDisplayPageStore,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
