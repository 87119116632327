import { useEffect, useCallback, useRef } from 'react';
import { useLockBodyScroll, useKey } from 'react-use';
import { useRouter } from 'next/router';
import { EventEmitter } from '@qb/frontend/utils/EventEmitter';

export const EVENT_SHOW_OMNISEARCH = 'EVENT_SHOW_OMNISEARCH';
export const EVENT_HIDE_OMNISEARCH = 'EVENT_HIDE_OMNISEARCH';

export const useRegisterOmniSearchHandlers = (
  isOpen: boolean,
  setIsOpen: (isOpen: boolean) => void,
) => {
  const inputRef = useRef<HTMLInputElement>();
  const router = useRouter();

  const showOmniSearch = useCallback(() => setIsOpen(true), [setIsOpen]);
  const hideOmniSearch = useCallback(() => setIsOpen(false), [setIsOpen]);

  useLockBodyScroll(isOpen);

  useKey('Escape', () => {
    setIsOpen(false);
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
  });

  useEffect(() => {
    EventEmitter.subscribe(EVENT_SHOW_OMNISEARCH, showOmniSearch);
    return () =>
      EventEmitter.unsubscribe(EVENT_SHOW_OMNISEARCH, showOmniSearch);
  }, [showOmniSearch]);

  useEffect(() => {
    EventEmitter.subscribe(EVENT_HIDE_OMNISEARCH, hideOmniSearch);
    return () =>
      EventEmitter.unsubscribe(EVENT_HIDE_OMNISEARCH, hideOmniSearch);
  }, [hideOmniSearch]);

  useEffect(() => {
    if (isOpen) {
      inputRef.current?.focus();
    }
  }, [isOpen]);

  useEffect(() => {
    const handleRouteChange = () => {
      hideOmniSearch();
    };

    router.events.on('routeChangeStart', handleRouteChange);
    return () => router.events.off('routeChangeStart', handleRouteChange);
  }, [router, hideOmniSearch]);

  return inputRef;
};
