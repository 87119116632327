import { recordEvent } from './ga4';
import { EcommerceItem } from './ga4Types';
import {
  GetGA4EcommerceListItemParams,
  getGA4EcommerceListItem,
  setGA4AdditionalDataInLocalStorage,
} from './utils';

type RecordSelectItemFromListForAnalyticsParams = {
  part: GetGA4EcommerceListItemParams['part'];
  itemIndex: EcommerceItem['index'];
  itemListName: EcommerceItem['item_list_name'];
};

export const recordSelectItemFromListForAnalytics = ({
  part,
  itemIndex,
  itemListName,
}: RecordSelectItemFromListForAnalyticsParams) => {
  if (!part) return;
  const listItem = getGA4EcommerceListItem({
    part,
    index: itemIndex,
  });

  recordEvent('select_item', {
    attributes: {
      item_id: listItem.item_id,
      item_name: listItem.item_name,
    },
    ecommerce: {
      item_list_id: itemListName,
      item_list_name: itemListName,
      items: [listItem],
    },
  });

  // set item's list name for the future
  setGA4AdditionalDataInLocalStorage(part.id, itemListName);
};
