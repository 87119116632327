import MailIcon from '@mui/icons-material/Mail';
import PhoneIcon from '@mui/icons-material/Phone';
import PinDropIcon from '@mui/icons-material/PinDrop';
import { Box, Container, Link as MuiLink, Stack, Typography } from '@mui/material';
import { CreditCards } from './CreditCards';
import { LinkedInLogotypeIcon } from './LinkedInLogotypeIcon';
export const BottomFooter = () => {
  const typographyStyle = {
    color: 'inherit',
    fontSize: 14,
    fontWeight: 600
  };
  return <Box sx={{
    backgroundColor: 'tertiary.dark'
  }} data-sentry-element="Box" data-sentry-component="BottomFooter" data-sentry-source-file="BottomFooter.tsx">
      <Container sx={{
      pt: 3,
      pb: 6
    }} data-sentry-element="Container" data-sentry-source-file="BottomFooter.tsx">
        <Stack spacing={2} data-sentry-element="Stack" data-sentry-source-file="BottomFooter.tsx">
          <Stack sx={{
          color: 'common.white',
          display: 'flex',
          flexDirection: {
            xs: 'column',
            lg: 'row'
          },
          alignItems: {
            xs: 'flex-start',
            lg: 'flex-end'
          },
          gap: {
            xs: 2,
            lg: 3,
            xl: 5
          }
        }} data-sentry-element="Stack" data-sentry-source-file="BottomFooter.tsx">
            <Stack direction="row" alignItems="center" gap={1} data-sentry-element="Stack" data-sentry-source-file="BottomFooter.tsx">
              <PinDropIcon data-sentry-element="PinDropIcon" data-sentry-source-file="BottomFooter.tsx" />
              <Typography sx={typographyStyle} data-sentry-element="Typography" data-sentry-source-file="BottomFooter.tsx">
                Quotebeam Inc., 166 Geary St, STE 1500, San Francisco, CA 94108
              </Typography>
            </Stack>
            <Stack direction="row" alignItems="center" gap={1} data-sentry-element="Stack" data-sentry-source-file="BottomFooter.tsx">
              <PhoneIcon data-sentry-element="PhoneIcon" data-sentry-source-file="BottomFooter.tsx" />
              <Typography sx={typographyStyle} data-sentry-element="Typography" data-sentry-source-file="BottomFooter.tsx">(747) 302-4552</Typography>
            </Stack>
            <Stack component={MuiLink} direction="row" alignItems="center" gap={1} href="mailto:customer@quotebeam.com" sx={{
            width: 'fit-content',
            color: 'inherit',
            transitionTimingFunction: 'easeInOut',
            transitionDuration: 'standard',
            transitionProperty: 'opacity',
            '&:hover': {
              opacity: 0.6
            }
          }} underline="none" data-sentry-element="Stack" data-sentry-source-file="BottomFooter.tsx">
              <MailIcon data-sentry-element="MailIcon" data-sentry-source-file="BottomFooter.tsx" />
              <Typography component="span" sx={typographyStyle} data-sentry-element="Typography" data-sentry-source-file="BottomFooter.tsx">
                customer@quotebeam.com
              </Typography>
            </Stack>
            <CreditCards sx={{
            display: {
              xs: 'block',
              lg: 'none'
            }
          }} data-sentry-element="CreditCards" data-sentry-source-file="BottomFooter.tsx" />
            <Stack component={MuiLink} direction="row" alignItems="flex-end" gap={1} href="https://www.linkedin.com/company/quotebeam" target="_blank" rel="noopener noreferrer" underline="none" sx={{
            ml: 'auto',
            mt: {
              xs: 6,
              md: -0.75
            },
            alignSelf: 'center',
            width: 'fit-content',
            color: 'inherit',
            transitionTimingFunction: 'easeInOut',
            transitionDuration: 'standard',
            transitionProperty: 'opacity',
            '&:hover': {
              opacity: 0.6
            }
          }} data-sentry-element="Stack" data-sentry-source-file="BottomFooter.tsx">
              <Typography component="span" sx={typographyStyle} data-sentry-element="Typography" data-sentry-source-file="BottomFooter.tsx">
                follow us on
              </Typography>
              <LinkedInLogotypeIcon data-sentry-element="LinkedInLogotypeIcon" data-sentry-source-file="BottomFooter.tsx" />
            </Stack>
          </Stack>
          <CreditCards sx={{
          display: {
            xs: 'none',
            lg: 'block'
          }
        }} data-sentry-element="CreditCards" data-sentry-source-file="BottomFooter.tsx" />
        </Stack>
      </Container>
    </Box>;
};