import { Box, Link, List, ListItem, Typography, Stack } from '@mui/material';
import { PartCategoryFooterCategoriesResponse } from '@/shared/types/controllers/PartCategoryControllerTypes';
import { FooterCategoriesLinks } from '@/src/components/Layouts/AppLayout/Footer/components/FooterCategoriesLinks';
import { footerNavData } from '../footerNavData';
export const navLinkSx = {
  fontWeight: 'fontWeightMedium',
  color: 'text.secondary',
  py: 1.5,
  display: 'block',
  '&:hover, &:focus': {
    color: 'primary.main',
    textDecoration: 'none'
  },
  '.Mui-expanded &.MuiTypography-bodyLarge': {
    fontWeight: 600
  }
};
type Props = {
  footerCategories: PartCategoryFooterCategoriesResponse['partCategories'];
};
export const FooterNav = ({
  footerCategories
}: Props) => <Stack direction={{
  xs: 'column',
  sm: 'row'
}} justifyContent={{
  xs: 'space-between',
  lg: 'space-evenly'
}} component="nav" sx={{
  mt: {
    xs: 2,
    lg: 0
  }
}} data-sentry-element="Stack" data-sentry-component="FooterNav" data-sentry-source-file="FooterNav.tsx">
    {footerNavData.map(({
    sectionName,
    elems
  }) => <Box key={sectionName}>
        <Typography component="h2" variant="h5">
          {sectionName}
        </Typography>
        <List sx={{
      py: 0.5,
      display: 'flex',
      flexDirection: 'column'
    }}>
          {elems.map(({
        href,
        text,
        target
      }) => <ListItem key={text} sx={{
        p: 0
      }}>
              <Link variant="bodyMedium" sx={navLinkSx} href={href} target={target || '_self'}>
                {text}
              </Link>
            </ListItem>)}
        </List>
      </Box>)}
    <Box data-sentry-element="Box" data-sentry-source-file="FooterNav.tsx">
      <Typography component="h2" variant="h5" data-sentry-element="Typography" data-sentry-source-file="FooterNav.tsx">
        Parts Categories
      </Typography>
      <List sx={{
      py: 0.5
    }} component="div" data-sentry-element="List" data-sentry-source-file="FooterNav.tsx">
        <FooterCategoriesLinks footerCategories={footerCategories} data-sentry-element="FooterCategoriesLinks" data-sentry-source-file="FooterNav.tsx" />
      </List>
    </Box>
  </Stack>;