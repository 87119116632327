'use client';

import { range } from 'lodash';
import { Box, Card, Skeleton, Stack, Typography } from '@mui/material';
import { useGetCurrentUserInfoRequest } from '@/src/components/Auth/hooks/useGetCurrentUserInfoRequest';
import { PartCategoryResult } from '@/src/components/OmniSearch/components/OmniSearchDesktopPopup/components/Categories/PartCategoryResult';
import { MascotAnimation } from '@/src/components/OmniSearch/components/OmniSearchDesktopPopup/components/MascotAnimation/MascotAnimation';
import { useSearchCategoriesRequest } from '@/src/components/OmniSearch/hooks/useSearchCategoriesRequest';
import { ViewAllCategoriesButton } from '../../ViewAllCategoriesButton';
export const NoQuery = () => {
  const {
    searchCategories,
    areSearchCategoriesLoading
  } = useSearchCategoriesRequest();
  const {
    isSuccess,
    data
  } = useGetCurrentUserInfoRequest();
  const firstName = isSuccess && (data?.user?.firstName || data?.pendingUser?.firstName);
  const greeting = `Hey ${firstName ? firstName : 'you'}! `;
  return <Card data-sentry-element="Card" data-sentry-component="NoQuery" data-sentry-source-file="NoQuery.tsx">
      <Stack direction="row" sx={{
      height: 500
    }} data-sentry-element="Stack" data-sentry-source-file="NoQuery.tsx">
        <Stack sx={{
        height: '100%',
        bgcolor: 'primary.4p',
        flex: 1,
        py: 1,
        px: 2
      }} direction="column" flexWrap="wrap" data-sentry-element="Stack" data-sentry-source-file="NoQuery.tsx">
          {!areSearchCategoriesLoading && searchCategories ? searchCategories.map(searchCategory => <PartCategoryResult partCategory={searchCategory} key={searchCategory.id} sx={{
          maxWidth: 200,
          height: 58
        }} />) : range(15).map(i => <Skeleton key={i} sx={{
          width: 200,
          height: 58
        }} />)}
          <ViewAllCategoriesButton sx={{
          height: 58
        }} data-sentry-element="ViewAllCategoriesButton" data-sentry-source-file="NoQuery.tsx" />
        </Stack>
        <Stack direction="column" alignItems="flex-end" width={235} p={2} data-sentry-element="Stack" data-sentry-source-file="NoQuery.tsx">
          <MascotAnimation variant="friendly" sx={{
          mb: 6
        }} data-sentry-element="MascotAnimation" data-sentry-source-file="NoQuery.tsx" />
          <Box sx={{
          position: 'relative',
          width: '100%',
          backgroundColor: 'primary.4p',
          p: 2,
          borderRadius: 2,
          '&:after': {
            position: 'absolute',
            content: '""',
            right: 0,
            top: -35,
            display: 'inline-block',
            width: 40,
            height: 40,
            backgroundColor: 'primary.4p',
            clipPath: 'polygon(100% 0, 50% 100%, 100% 100%)'
          }
        }} data-sentry-element="Box" data-sentry-source-file="NoQuery.tsx">
            <Typography variant="h5" mb={1} data-sentry-element="Typography" data-sentry-source-file="NoQuery.tsx">
              {greeting}
            </Typography>
            <Typography variant="bodyMedium" data-sentry-element="Typography" data-sentry-source-file="NoQuery.tsx">
              Check out our parts categories!
            </Typography>
          </Box>
        </Stack>
      </Stack>
    </Card>;
};