'use client';

import { Box, Button, Stack, Typography } from '@mui/material';
import { ReactComponent as ThreeDots } from '../../assets/three-dots.svg';
export const NoPartResults = () => {
  return <Stack sx={{
    height: '100%',
    alignItems: 'flex-end',
    justifyContent: 'flex-end'
  }} data-sentry-element="Stack" data-sentry-component="NoPartResults" data-sentry-source-file="NoPartResults.tsx">
      <Box sx={{
      width: 330,
      height: 100,
      bgcolor: 'primary.8p',
      borderRadius: 2,
      p: 2,
      mb: 2
    }} data-sentry-element="Box" data-sentry-source-file="NoPartResults.tsx">
        <Typography variant="h6" component="p" data-sentry-element="Typography" data-sentry-source-file="NoPartResults.tsx">
          {`Don't see the part you're looking for? Write to us and we will help you
      find it.`}
        </Typography>
      </Box>
      <ThreeDots data-sentry-element="ThreeDots" data-sentry-source-file="NoPartResults.tsx" />
      <Button variant="contained" color="primary" fullWidth onClick={e => {
      e.preventDefault();
      window?.salesPilotPostMessages?.openChatPage();
    }} sx={{
      mt: 4
    }} data-sentry-element="Button" data-sentry-source-file="NoPartResults.tsx">
        Chat with us
      </Button>
    </Stack>;
};