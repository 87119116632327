/* eslint-disable fp/no-mutating-methods */
import { toast } from 'react-toastify';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import noop from 'lodash/noop';
import { PostRequest } from '@qb/httpRequest/Request';
import { WebAPIFullURL } from '@qb/httpRequest/constants';
import {
  ConversationSearchResponse,
  ConversationListItemType,
  ConversationDisplayDataResponse,
  MessageType,
} from '@/shared/types/controllers/ConversationControllerTypes';
import type { User } from '@/src/types/types';
import {
  fetchConversationData,
  fetchConversationListData,
  fetchConversationsBySearch,
} from './thunks';

export type ConversationPageState = {
  conversations: ConversationListItemType[];
  searchConversationResults: ConversationSearchResponse['searchConversationResults'];
  selectedConversationID: null | number;
  selectedConversation: ConversationDisplayDataResponse['conversation'] | null;
  selectedConversationFetchedAt: null | number;
  hasMoreConversationsToFetch: boolean;
  isLoading: boolean;
  isLoadingSearch: boolean;
};

export const getInitialStore = (): ConversationPageState => ({
  conversations: [],
  searchConversationResults: [],
  selectedConversationID: null,
  selectedConversation: null,
  selectedConversationFetchedAt: null,
  hasMoreConversationsToFetch: true,
  isLoading: true,
  isLoadingSearch: true,
});

const conversationSlice = createSlice({
  name: 'conversationPage',
  initialState: getInitialStore(),
  reducers: {
    setConversations: (
      state,
      { payload }: PayloadAction<ConversationListItemType[]>,
    ) => {
      state.conversations = payload;
    },
    addConversation: (
      state,
      { payload }: PayloadAction<ConversationListItemType>,
    ) => {
      for (let i = 0; i < state.conversations.length; i++) {
        if (state.conversations[i].id === payload.id) {
          // Do not add if array already contains this conversation
          return;
        }
      }
      state.conversations = [payload, ...state.conversations];
      state.conversations = state.conversations.sort((a, b) => {
        // @ts-expect-error TODO: we need to fix this and test it properly.
        return new Date(a.computedSortAt) - new Date(b.computedSortAt);
      });
    },
    setSelectedConversationID: (state, { payload }) => {
      if (payload && payload !== state.selectedConversationID) {
        window.history.replaceState(
          null,
          '',
          `/conversation/${payload}/display`,
        );
        state.selectedConversationID = payload;
      }
    },
    addMessage: (
      state,
      { payload }: PayloadAction<{ message: MessageType; user: User }>,
    ) => {
      state.conversations.forEach((conversation) => {
        if (conversation.id === payload.message.conversation) {
          conversation.messages.push(payload.message);
          if (
            payload.message.contact ||
            (payload.user &&
              payload.message.author &&
              payload.message.author.id === payload.user.id)
          ) {
            conversation.computedSortAt = payload.message.createdAt;
          }
        }
      });
      if (
        state.selectedConversation &&
        payload.message.conversation === state.selectedConversation.id
      ) {
        state.selectedConversation.messages.push(payload.message);
      }
    },
    addConversationMemberships: (state, { payload }) => {
      state.conversations.forEach((conversation) => {
        if (conversation.id === payload.conversation) {
          if (!conversation.conversationMemberships) {
            conversation.conversationMemberships = [];
          }
          conversation.conversationMemberships.push(payload);
        }
      });
      if (
        state.selectedConversation &&
        payload.conversation === state.selectedConversation.id
      ) {
        if (!state.selectedConversation.conversationMemberships) {
          state.selectedConversation.conversationMemberships = [];
        }
        state.selectedConversation.conversationMemberships.push(payload);
      }
    },
    setFirstResponse: (state, { payload }) => {
      state.conversations.forEach((conversation) => {
        if (conversation.id === payload.conversationID) {
          conversation.firstResponseAt = payload.firstResponseAt;
        }
      });
    },
    setNumUnreadMessages: (state, { payload }) => {
      state.conversations.forEach((conversation) => {
        if (conversation.id === payload.conversationID) {
          conversation.numUnreadMessages = payload.numUnreadMessages;
        }
      });
    },
    incrementNumUnreadMessages: (state, { payload }) => {
      state.conversations.forEach((conversation) => {
        if (conversation.id === payload.conversationID) {
          conversation.numUnreadMessages += 1;
        }
      });
    },

    updateConversationComputedSortAt: (state, { payload }) => {
      state.conversations.forEach((conversation) => {
        if (conversation.id === payload.conversationID) {
          conversation.computedSortAt = new Date().toISOString();
        }
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchConversationData.fulfilled, (state, { payload }) => {
      if (payload.conversation) {
        if (payload.setAsSelected) {
          state.selectedConversation = payload.conversation;
          state.selectedConversationID = payload.conversation.id;
          state.selectedConversationFetchedAt = Date.now();
          window.history.replaceState(
            null,
            '',
            `/conversation/${payload.conversation.id}/display`,
          );
        }
        const alreadyContainsConversation = state.conversations.some(
          (conversation) => {
            return payload.conversation.id === conversation.id;
          },
        );
        if (!alreadyContainsConversation) {
          state.conversations = [payload.conversation].concat(
            state.conversations,
          ) as ConversationListItemType[];

          state.conversations = state.conversations.sort((a, b) => {
            // @ts-expect-error TODO: we need to fix this and test it properly.
            return new Date(a.computedSortAt) - new Date(b.computedSortAt);
          });
        }
      }
    });
    builder.addCase(fetchConversationData.rejected, (_state, { error }) => {
      console.error(error);
      toast.error(
        'Unable to load conversation. Reload the page or try again later',
      );
    });
    builder.addCase(fetchConversationListData.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      fetchConversationListData.fulfilled,
      (state, { payload }) => {
        for (let i = 0; i < payload.conversations.length; i++) {
          for (let j = state.conversations.length - 1; j >= 0; j--) {
            if (state.conversations[j].id === payload.conversations[i].id) {
              // Do not add if array already contains this conversation
              break;
            }
          }
          state.conversations = state.conversations.concat(
            payload.conversations[i],
          );
        }
        state.conversations = state.conversations.sort((a, b) => {
          // @ts-expect-error TODO: we need to fix this and test it properly.
          return new Date(a.computedSortAt) - new Date(b.computedSortAt);
        });
        state.isLoading = false;
        state.hasMoreConversationsToFetch = payload.conversations.length === 10;
      },
    );
    builder.addCase(fetchConversationListData.rejected, (state, { error }) => {
      PostRequest(
        `${WebAPIFullURL}/analytics/logFrontendMessage`,
        {
          message:
            'Error when fetching conversations: ' + JSON.stringify(error),
        },
        noop,
        noop,
      );
      toast.error('Unable to load conversations. Reloading...');
      setTimeout(() => {
        window.location.reload();
      }, 2500);
      state.isLoading = false;
    });
    builder.addCase(fetchConversationsBySearch.pending, (state) => {
      state.isLoadingSearch = true;
    });
    builder.addCase(
      fetchConversationsBySearch.fulfilled,
      (state, { payload }) => {
        state.isLoadingSearch = false;
        state.searchConversationResults = payload.searchConversationResults;
      },
    );
    builder.addCase(fetchConversationsBySearch.rejected, (state, { error }) => {
      state.isLoadingSearch = false;
      PostRequest(
        `${WebAPIFullURL}/analytics/logFrontendMessage`,
        {
          message:
            'Error when searching conversations: ' + JSON.stringify(error),
        },
        noop,
        noop,
      );
      toast.error('Unable to search conversations. Please try again later');
    });
  },
});

export const {
  setConversations,
  addConversation,
  setSelectedConversationID,
  addMessage,
  addConversationMemberships,
  setFirstResponse,
  setNumUnreadMessages,
  incrementNumUnreadMessages,
  updateConversationComputedSortAt,
} = conversationSlice.actions;

export default conversationSlice.reducer;
