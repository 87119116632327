'use client';

import { Chip, useTheme } from '@mui/material';
export const FastShippingChip = () => {
  return <Chip label="Fast shipping" size="small" icon={<ShippingBoxIcon />} sx={{
    /** We can't use `color` prop directly on the component, because it allows only "success" but not "success.8p" */
    bgcolor: 'success.8p',
    pl: 1
  }} data-sentry-element="Chip" data-sentry-component="FastShippingChip" data-sentry-source-file="FastShippingChip.tsx" />;
};
const ShippingBoxIcon = () => {
  const theme = useTheme();
  return <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-component="ShippingBoxIcon" data-sentry-source-file="FastShippingChip.tsx">
      <path d="M19.268 4.13106L12.3931 0.349811C12.2915 0.293963 12.1775 0.264679 12.0617 0.264679C11.9458 0.264679 11.8318 0.293963 11.7303 0.349811L4.8553 4.13106C4.74751 4.19041 4.65762 4.2776 4.59502 4.38353C4.53241 4.48947 4.49939 4.61026 4.49939 4.73331C4.49939 4.85636 4.53241 4.97715 4.59502 5.08309C4.65762 5.18902 4.74751 5.27621 4.8553 5.33556L11.3742 8.92087V16.2902L9.28692 15.1421L8.62418 16.3459L11.7303 18.0543C11.8318 18.1103 11.9458 18.1396 12.0617 18.1396C12.1776 18.1396 12.2916 18.1103 12.3931 18.0543L19.268 14.2731C19.3759 14.2137 19.4658 14.1266 19.5285 14.0206C19.5911 13.9147 19.6242 13.7939 19.6242 13.6708V4.73331C19.6242 4.61024 19.5911 4.48943 19.5285 4.3835C19.4658 4.27756 19.3759 4.19038 19.268 4.13106ZM12.0617 1.73719L17.5101 4.73331L12.0617 7.72944L6.61324 4.73331L12.0617 1.73719ZM18.2492 13.2645L12.7492 16.2895V8.92019L18.2492 5.89519V13.2645Z" fill={theme.palette.success.main} data-sentry-element="path" data-sentry-source-file="FastShippingChip.tsx" />
      <path d="M5.875 9H0.375V7.625H5.875V9ZM7.25 14.5H1.75V13.125H7.25V14.5ZM8.625 11.75H3.125V10.375H8.625V11.75Z" fill={theme.palette.success.main} data-sentry-element="path" data-sentry-source-file="FastShippingChip.tsx" />
    </svg>;
};